
import Vue, { PropType } from "vue";

import { mapState } from "vuex";

import MyGarbage from "@/components/building/MyGarbage.vue";
import { Building, Snackbar } from "@/interfaces";

import { mdiPlus } from "@mdi/js";

import axios from "axios";

export interface DataType {
  dialog: boolean;
  content: string;
}

export interface Body {
  name: string;
  idList: string;
  desc: string;
}

export default Vue.extend({
  components: {
    MyGarbage
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true
    }
  },
  data(): DataType {
    return {
      dialog: false,
      content: ""
    };
  },
  computed: {
    icon() {
      return {
        mdiPlus
      };
    },
    hasGarbage(): boolean {
      return !!this.building.garbage;
    },
    ...mapState(["user"])
  },
  methods: {
    async post(data: Body) {
      const { VUE_APP_TRELLO_KEY, VUE_APP_TRELLO_TOKEN } = process.env;

      const url = `https://api.trello.com/1/cards`;

      const params = {
        key: VUE_APP_TRELLO_KEY,
        token: VUE_APP_TRELLO_TOKEN
      };

      const res = await axios.post(url, data, {
        params
      });

      const snackbar: Snackbar = {
        show: true,
        content: "リクエストを送信しました",
        color: "success"
      };

      this.$store.commit("setSnackbar", snackbar);
    },
    async edit() {
      const { VUE_APP_TRELLO_LIST_ID, VUE_APP_REDIRECT_URL } = process.env;

      const desc = `
${VUE_APP_REDIRECT_URL}/building/${this.building.id}/garbage

${this.content}
      
from:${this.user.email}
      `;

      const data: Body = {
        name: `ゴミ修正:${this.building.name}`,
        idList: `${VUE_APP_TRELLO_LIST_ID}`,
        desc
      };

      await this.post(data);

      this.dialog = false;
    },
    async create() {
      const { VUE_APP_TRELLO_LIST_ID, VUE_APP_REDIRECT_URL } = process.env;

      const desc = `
${VUE_APP_REDIRECT_URL}/building/${this.building.id}/garbage

from:${this.user.email}
      `;

      const data = {
        name: `ゴミ登録:${this.building.name}`,
        idList: `${VUE_APP_TRELLO_LIST_ID}`,
        desc
      };

      await this.post(data);
    },
    async click() {
      if (this.hasGarbage) {
        await this.edit();
      } else {
        await this.create();
      }
    }
  }
});
