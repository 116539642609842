
import Vue, { PropType } from "vue";

import { Building, Garbage, GarbageStation } from "@/interfaces";

import MyMap from "@/components/MyMap.vue";
import MyPreview from "@/components/MyPreview.vue";

import http from "@/http";
import { mdiPencil, mdiDelete, mdiPlus, mdiContentCopy } from "@mdi/js";
export default Vue.extend({
  components: {
    MyMap,
    MyPreview
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true
    }
  },
  computed: {
    icon() {
      return { mdiPencil, mdiDelete, mdiPlus, mdiContentCopy };
    },
    garbage(): Garbage | undefined {
      return this.building.garbage;
    }
  },
  methods: {
    async remove(station: GarbageStation) {
      const url = `garbage_stations/${station.id}`;

      await http.delete(url, {});

      if (this.garbage) {
        this.garbage.garbage_stations = this.garbage.garbage_stations.filter(
          item => {
            return item.id !== station.id;
          }
        );
      }
    }
  }
});
